import { Component } from 'react';

import { Table, Row } from '../components/Table';
import History from '../components/History';
import * as NumberUtils from '../utils/NumberUtils';
import * as JsonUtils from '../utils/JsonUtils';
import { occurrences } from '../utils/Constants';

export default class CostCalculator extends Component {

  constructor(props) {
    super(props);

    this.state = {
      data: [],
      error: '',
      occurrence: 'monthly',

      dailyTotal: 0,
      monthlyTotal: 0,
      yearlyTotal: 0
    }

    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleDelete = this.handleDelete.bind(this);
    this.updateState = this.updateState.bind(this);
  }

  handleChange(e) {
    this.setState({
      [e.target.name]: e.target.value
    })
  }

  handleSubmit(e) {
    e.preventDefault();
    if (!this.state.name || this.state.name.length === 0) {
      this.setState({ error: `Name cannot be empty!` });
      return;
    }

    if (!this.state.amount || this.state.amount === 0) {
      this.setState({ error: `Amount cannot be empty!` });
      return;
    }

    const bill = {
      name: this.state.name,
      amount: this.state.amount,
      occurrence: this.state.occurrence ?? 'monthly'
    };

    const data = this.state.data;
    data.push(bill);

    this.updateState(data);
    this.setState({ name: '', amount: '', occurrence: 'monthly' });
  }

  handleDelete(idx) {
    const data = this.state.data;
    data.splice(idx, 1);

    this.updateState(data);
  }

  updateTotal(data) {
    let dailyTotal = 0;
    let monthlyTotal = 0;
    let yearlyTotal = 0;
    for (const bill of data) {
      if (bill.occurrence === 'daily') {
        dailyTotal += Number.parseFloat(bill.amount);
        monthlyTotal += NumberUtils.fromDayToMonth(bill.amount);
        yearlyTotal += NumberUtils.fromDayToYear(bill.amount);
      } else if (bill.occurrence === 'weekly') {
        dailyTotal += NumberUtils.fromWeekToDay(bill.amount);
        monthlyTotal += NumberUtils.fromWeekToMonth(bill.amount);
        yearlyTotal += NumberUtils.fromWeekToYear(bill.amount);
      } else if (bill.occurrence === 'monthly') {
        dailyTotal += NumberUtils.fromMonthToDay(bill.amount);
        monthlyTotal += Number.parseFloat(bill.amount);
        yearlyTotal += NumberUtils.fromMonthToYear(bill.amount);
      } else if (bill.occurrence === 'yearly') {
        dailyTotal += NumberUtils.fromYearToDay(bill.amount);
        monthlyTotal += NumberUtils.fromYearToMonth(bill.amount);
        yearlyTotal += Number.parseFloat(bill.amount);
      }
    }

    this.setState({ dailyTotal, monthlyTotal, yearlyTotal });
  }

  updateQueryString(data) {
    if (data.length === 0) {
      History.replace('');
    } else {
      const minifiedJson = JsonUtils.minify(data);
      History.replace('?json=' + btoa(JSON.stringify(minifiedJson)));
    }
  }

  updateState(data) {
    this.updateTotal(data);
    this.updateQueryString(data);
    this.setState({ data });
  }

  componentDidMount() {
    // Try and read if there is any stored JSON
    if (this.props.location.search) {
      const urlParams = new URLSearchParams(this.props.location.search);

      // Get the JSON, enforce it has a value and check it starts with [.
      const base64 = urlParams.get('json');
      if (base64 && base64.startsWith('W3')) {
        const decoded = atob(base64);

        const json = JSON.parse(decoded);

        // Convert from the minified JSON into the more verbose state
        const unminified = JsonUtils.unminify(json);

        this.updateState(unminified);
      }
    }
  }

  render() {
    return (
      <div>
        <h3>Daily Total: £{this.state.dailyTotal.toFixed(2)}</h3>
        <h3>Monthly Total: £{this.state.monthlyTotal.toFixed(2)}</h3>
        <h3>Yearly Total: £{this.state.yearlyTotal.toFixed(2)}</h3>

        {this.state.error && <div style={{color: 'red'}}>{this.state.error}</div>}

        <input
          name='name'
          type='text'
          value={this.state.name}
          onChange={this.handleChange}
        />
        <input
          name='amount'
          type='number'
          value={this.state.amount}
          onChange={this.handleChange}
        />

        <select name='occurrence' value={this.state.occurrence} onChange={this.handleChange}>
          {occurrences.map(occ => {
            return <option key={occ} value={occ}>{occ.charAt(0).toUpperCase() + occ.slice(1)}</option>
          })}
          {/* <option value="daily">Daily</option>
          <option value="weekly">Weekly</option>
          <option value="monthly">Monthly</option>
          <option value="yearly">Yearly</option> */}
        </select>

        <button name='+' onClick={this.handleSubmit}>+</button>

        <Table
          columns={['Name', 'Amount', 'Occurrence', 'Actions']}
          rows={this.state.data.map((item, idx) => {
            return <Row key={idx} values={[
              item.name,
              item.amount,
              item.occurrence,
              <button onClick={() => this.handleDelete(idx)}>Delete</button>
            ]} />
          })}
        />
      </div>
    );
  }
}
