import { Component } from 'react';

import * as styles from '../index.css';

const CAs = {
  digicert: 'DigiCert',
  lets_encrypt: 'Let’s Encrypt',
  // globalsign: 'GlobalSign',
  sectigo: 'Sectigo'
};

export default class CAChanger extends Component {

  constructor(props) {
    super(props);

    this.state = {
      zoneId: '',
      email: '',
      globalKey: '',
      ca: 'digicert',

      message: '',
      error: '',
    }

    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  handleChange(e) {
    this.setState({
      [e.target.name]: e.target.value
    })
  }

  handleSubmit(e) {
    e.preventDefault();

    const { zoneId, email, globalKey, ca } = this.state;

    if (!zoneId || zoneId.length === 0) {
      this.setState({ error: 'Zone ID cannot be empty!' });
      return;
    } else if (!email || email.length === 0) {
      this.setState({ error: 'Email cannot be empty!' });
      return;
    } else if (!globalKey || globalKey.length === 0) {
      this.setState({ error: 'Global Key cannot be empty!' });
      return;
    } else if (!ca || ca.length === 0) {
      this.setState({ error: 'CA cannot be empty!' });
      return;
    }

    fetch(`https://cf-cors.walshydev.workers.dev/client/v4/zones/${zoneId}/ssl/universal/settings`, {
      method: 'PATCH',
      headers: {
        'X-Auth-Email': email,
        'X-Auth-Key': globalKey,
      },
      body: JSON.stringify({
        certificate_authority: ca,
      }),
    })
      .then(res => res.json())
      .then(obj => {
        if (obj.success === true) {
          this.setState({ message: 'Success!' });
        } else {
          const msg = obj.errors[0];

          this.setState({ error: `${msg.code}: ${msg.message}` });
        }
      })
  }

  render() {
    return (
      <div>
        <div>
          <label for="zoneId">Zone ID:</label>
          <input name="zoneId" value={this.state.zoneId} onChange={this.handleChange} />
        </div>

        <div>
          <label for="email">Account Email:</label>
          <input name="email" value={this.state.email} onChange={this.handleChange} />

          <label for="globalKey">Global Key:</label>
          <input name="globalKey" value={this.state.globalKey} onChange={this.handleChange} />
        </div>
      
        <div>
          <label for="ca">Certiciate Authority:</label>
          <select name='ca' value={this.state.ca} onChange={this.handleChange}>
            {Object.keys(CAs).map(ca => {
              console.log(ca);
              return <option key={ca} value={ca}>{CAs[ca]}</option>
            })}
          </select>
        </div>

        <button onClick={this.handleSubmit}>Update CA</button>

        <div className={this.state.error ? styles.error : ''}>
          {this.state.error ? this.state.error : this.state.message}
        </div>
      </div>
    );
  }
}
