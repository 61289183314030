export function Table(props) {

  return (
    <table>
      <thead>
        <tr>
          {props.columns.map(column => {
            return <th key={column}>{column}</th>
          })}
        </tr>
      </thead>

      <tbody>
        {props.rows && props.rows.map(row => {
          return row;
        })}
      </tbody>
    </table>
  );
}

export function Row(props) {
  return (
    <tr>
      {props.values.map((value, idx) => {
        return <td key={idx}>{value}</td>
      })}
    </tr>
  )
}