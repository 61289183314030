import { occurrences } from '../utils/Constants';
import * as ArrayUtils from '../utils/ArrayUtils';

export function minify(data) {
  // Convert from { name: 'Name', amount: 11.1, occurrence: 'monthly' } -> { n: 'Name', a: 11.1, o: 3 }
  return data.map(obj => {
    const idx = ArrayUtils.findIndex(occurrences, obj.occurrence);

    return { n: obj.name, a: Number.parseFloat(obj.amount), o: idx }
  });
}

export function unminify(data) {
  // Convert from { n: 'Name', a: 11.1, o: 3 } -> { name: 'Name', amount: 11.1, occurrence: 'monthly' }
  return data.map(obj => {
    const occurrence = occurrences[obj.o];

    return { name: obj.n, amount: obj.a, occurrence: occurrence }
  })
}