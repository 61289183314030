import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';

import './index.css';

import HelloWorld from './pages/HelloWorld';
import CostCalculator from './pages/CostCalculator';
import CAChanger from './pages/CAChanger';

import History from './components/History';

ReactDOM.render(
  <React.StrictMode>
    <Router history={History}>
      <Switch>
        <Route key='index' exact path='/' component={HelloWorld} />
        <Route key='cost-calculator' exact path='/cost-calculator' component={CostCalculator} />
        <Route key='ca-changer' exact path='/ca-changer' component={CAChanger} />
      </Switch>
    </Router>
  </React.StrictMode>,
  document.getElementById('root')
);