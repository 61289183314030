///////////////
// To Month
///////////////
export function fromWeekToDay(amount) {
  return (amount * 52) / 365;
}

export function fromMonthToDay(amount) {
  return (amount * 12) / 365;
}

export function fromYearToDay(amount) {
  return amount / 365;
}

///////////////
// To Month
///////////////
export function fromDayToMonth(amount) {
  return (amount * 365) / 12;
}

export function fromWeekToMonth(amount) {
  return (amount * 52) / 12;
}

export function fromYearToMonth(amount) {
  return amount / 12;
}

///////////////
// To Year
///////////////
export function fromDayToYear(amount) { 
  return amount * 365;
}

export function fromWeekToYear(amount) {
  return amount * 52;
}

export function fromMonthToYear(amount) {
  return amount * 12;
}